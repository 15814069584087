export default {
  data() {
    return {
      def_user_img: require('../assets/images/user/user-avatar.png'),
      user_avatar: require('../assets/images/user/user-avatar.jpeg'),
      user_1: require('../assets/images/user/user1.jpg'),
      def_cattle_img: require('../assets/images/ba-lin-you-qi/xiao-niu-def.svg'),
      def_yi_miao_img: require('../assets/images/yi-miao/def-yi-miao.png'),
    }
  }
}