import request from '@/util/request'

// 获取 仓库列表
export function getStoreList() {
  return request({
      url: `/bigdata/erp/store/list`,
      method: 'post',
  })
}

// 获取 疫苗库存 信息
export function getInventory(data) {
  return request({
      url: `/bigdata/erp/inventory/page`,
      method: 'post',
      data
  })
}

// 获取 疫苗 出库 入库 信息
export function getInventoryChangeLog(data) {
  return request({
      url: `/bigdata/erp/inventoryChangeLog/page`,
      method: 'post',
      data
  })
}

// 获取 巴林右旗 xxx 类型的疫苗
export function getCountyVaccinumStatistics(vaccinumTypeId, vaccinumId) {
  return request({
      url: `/app/animal/bigData/getCountyVaccinumStatistics?vaccinumTypeId=${vaccinumTypeId}&vaccinumId=${vaccinumId}`,
      method: 'get'
  })
}

// 获取 疫苗列表
export function getVaccinumList(data) {
  return request({
      url: `/bigdata/animal/vaccinum/list`,
      method: 'post',
      data
  })
}

// 获取 疫苗类型 列表
export function getVaccinumTypeList() {
  return request({
      url: `/app/animal/bigData/getVaccinumTypeList`,
      method: 'get'
  })
}

// 通过 疫苗id 获取 疫苗免疫统计
export function getVaccinumStatistics(vaccinumId) {
  return request({
      url: `/app/animal/bigData/getVaccinumStatistics?vaccinumId=${vaccinumId}`,
      method: 'get'
  })
}

// 通过 疫苗id 获取 未打疫苗的动物列表
export function getVaccinumstatisticList(vaccinumId) {
  return request({
      url: `/app/animal/bigData/getVaccinumstatisticList?vaccinumId=${vaccinumId}`,
      method: 'get'
  })
}

// 获取 动防站点 下的疫苗统计
export function getSiteVaccinumStatistics(siteId, vaccinumTypeId) {
  return request({
      url: `/app/animal/bigData/getSiteVaccinumStatistics?siteId=${siteId}&vaccinumTypeId=${vaccinumTypeId}`,
      method: 'get'
  })
}

// 获取 动防站点下的 疫苗入库列表
export function getSiteVaccinumRecordList(siteId, vaccinumId) {
  return request({
      url: `/app/animal/bigData/getSiteVaccinumRecordList?siteId=${siteId}&vaccinumId=${vaccinumId}`,
      method: 'get'
  })
}


