<template>
    <div class="body_bgi full border_box">
      <big-screen-header :headerName="storeName + '疫苗信息'" @selectJob="selectJob" />
  
      <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
        <!-- 1 -->
        <div class="w_32_p h_full flex_column_between">
          <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1 scroll_box">
            <div class="text_1 jian_bian_text_1 fw_bold pb_10">站点负责人</div>
  
            <div class="w_full h_50_p">
              <div class="w_30_p h_full m_0_auto">
                <img 
                  v-if="personData.avatar"
                  class="border_radius_10 cursor response_img" 
                  :src='filePath + `${personData.avatar}`' alt=""
                >
  
                <img 
                  class="border_radius_10 cursor response_img" 
                  v-else
                  :src='def_user_img' alt=""
                >
              </div>
            </div>
  
            <div class="w_full h_40_p color_fff pv_20 border_box">
              <div class="flex_row_between pr_20">
                <div class="h_24 lh_24 mb_3">姓名： <span class="main_color">{{ personData.name }}</span> </div>
                <div class="h_24 lh_24 mb_3">民族： <span class="main_color">{{ personData.nationName }}</span></div>
              </div>
              
              <div class="flex_row_between pr_20">
                <div class="h_24 lh_24 mb_3">
                  电话：<span class="main_color fw_bold font_number ls_2">{{ formatPhone(personData.phone) }}</span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
            <yi-miao-chart
              title="疫苗库存统计"
              path="/storePage" 
              :chartData="chartData"
              @clickYiMiao="handleClickVaccinum"
              @selectJob="selectJob"
            />
          </div>
        </div> 
  
        <!-- 2 -->
        <div class="w_32_p h_full flex_column_between">
          <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
            <div class="text_1 fw_bold jian_bian_text_1">疫苗信息</div>
  
            <div class="w_full h_full-40 flex_column_between mt_10">
              <!-- 疫苗图片 疫苗信息 -->
              <div class="w_full h_full">
                <div class="w_full h_70_p m_0_auto">
                  <img 
                    v-if="currentVaccinumInfo.picture"
                    class="h_full border_radius_10 cursor response_img" 
                    :src='filePath + `${currentVaccinumInfo.picture}`' alt=""
                  >
  
                  <img 
                    class="h_full border_radius_10 cursor response_img" 
                    v-else
                    :src='def_yi_miao_img' alt=""
                  >
                </div>
  
                <div class="w_full h_30_p ph_10 main_color">
                  <div class="w_full p_5">
                    疫苗名称: 
                    <span class="theme_color_green cursor">
                      {{ currentVaccinumInfo.name }}
                    </span>
                  </div> 
  
                  <div class="w_full h_70 scroll_box overflow_y_auto p_5">
                    说明:
                    <span class="color_fff"> {{ currentVaccinumInfo.description }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

           <!-- 疫苗入库记录 -->
          <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
            <div class="full cursor text_1 color_fff flex_column_between">
                <div class="text_1 fw_bold jian_bian_text_1">疫苗库存明细</div>
  
                <div class="w_full h_full-40 overflow_y_auto ph_10 scroll_box">
                  <div 
                    class="cursor text_1 color_fff flex_row_between"
                    v-for="(item, index) in vaccinumInboundList" :key="index"
                  >
                    <div class="w_30_p p_5">
                      <span class="main_color font_number"> {{ formatDate(item.createTime) }}</span>
                    </div> 
                    <div class="w_30_p p_5">
                      <span v-if="item.changeType == 1">领取：</span> 
                      <span v-if="item.changeType == -1">发放：</span> 
                      <span v-if="item.changeType == 2">接收：</span> 
                      <span v-if="item.changeType == -2">退还：</span> 
                      <span class="main_color font_number"> {{ item.changeNum }}</span>
                    </div> 
                    <div class="w_30_p p_5">
                      库存：
                      <span class="main_color font_number"> {{ item.amount }}</span>
                    </div> 
                  </div>
                </div>
              </div>
          </div>
        </div> 
  
        <!-- 3 -->
        <div class="w_32_p h_full p_10 border_box bgs_full chart_box_bgi_2">
          <div class="text_1 jian_bian_text_1 fw_bold">疫苗分发记录</div>
          <!-- 防疫员 列表 -->
          <div class="scroll_box w_full h_95_p overflow_auto mt_10">
            <dv-loading v-if="!vaccinumOutboundList.length" class="color_fff">数据加载中...</dv-loading>
            <div
              class="w_full h_20_p p_10 border_box flex_row_between mb_10" 
              v-for="(item, index) in vaccinumOutboundList" :key="index"
              @click="goToErpPerson(item)"
            >
              <!-- 头像 -->
              <div class="w_30_p border_radius_10">
                <img 
                  class="border_radius_10 cursor response_img" 
                  :src="filePath + item.targetManagerAvatar || def_user_avatar_img" alt=""
                >
              </div>
  
              <!-- 文字信息 -->
              <div class="info_box w_70_p flex_column_start pl_20 cursor">
                <div class="text_1 color_green">防疫员: {{ item.targetStoreName || ''}}</div>
                <div class="text_1 color_fff">电话: <span class="font_number">{{ item.targetManagerPhone || ''}}</span></div>
                <div class="text_1 color_fff font_number">分发剂量: <span class="main_color ml_10">{{ item.changeNum }}</span>  （毫升）</div>
              </div>
            </div>
          </div> 
        </div> 
      </div>
    </div>
  </template>
  
  <script>
    import defMixin from '@/mixins/def.js' 
    import componentsMixin from '@/mixins/components.js'
    import imgMixin from '@/mixins/def-img.js'
    import dataChartMixin from '@/mixins/data-charts.js'
  
    import { getHeadInfoBySiteId } from '@/api/base/base.js'
    import { getInventory, getInventoryChangeLog } from '@/api/fang-yi/yi-miao.js'
    import { getVaccinumTree } from '@/api/fang-yi/fang-yi.js'
  
    import { filePath } from "@/const"
  
    export default {
      name: 'ErpSite',
      mixins: [defMixin, componentsMixin, imgMixin, dataChartMixin],
      data() {
        return {
          jobId: null,
          filePath: filePath,

          chartData: {
            unit: '毫升、头份',
            isShowUnit: true,
            data: []
          },
  
          storeId: null,
          storeName: null,
          headId: null,
  
          vaccinumList: [],
  
          // 动防站点 【负责人】 信息
          personData: {
            name: '',
            gender: '',
            nationName: '',
            address: '',
            townName: '',
            phone: ''
          },
  
          currentVaccinumInfo: {},
          vaccinumInboundList: [],
          vaccinumOutboundList: []
        }
      },
  
      async mounted() {
        let storeId = this.$route.params.storeId
        let storeName = this.$route.params.storeName
        let headId = this.$route.params.headId

        this.storeId = storeId
        this.storeName = storeName
        this.headId = headId
  

        // 获取 动防站点 - 负责人信息
        await this.getHeadInfoBySiteId(this.headId)
      },
  
      methods: {
        // 获取 疫苗 库存 信息
        async getInventoryList(storeId, jobId) {
          let params = {
            storeId,
            jobId,
            page: 1,
            size: 1000
          }
          let res = await getInventory(params)
          try {
            if (res.code == 1000 && res.data.list.length) {
              this.chartData = {
                unit: '毫升、头份',
                isShowUnit: true,
                data: this.formatYiMiaoList(res.data.list)
              }
  
              // 显示 默认 疫苗详情
              let defaultVaccinum = {id: res.data.list[0].goodsId, name: res.data.list[0].goodsName}
              this.handleClickVaccinum(defaultVaccinum)
            } else {
              this.chartData = {}
              this.currentVaccinumInfo = {}
              this.vaccinumInboundList = []
              this.vaccinumOutboundList = []
            }
          } catch (err) {
            console.log(err)
          }
        },

        // 获取疫苗入库信息
        async getInventoryInBountList(storeId, goodsId) {
          this.vaccinumInboundList = []
          // const CHANGETYPE_INITIAL: number = 0; // 库存登记-入库 (中心 入库)
          // const CHANGETYPE_RECIEVE_FROM: number = 1; // 领取上级给的-入库 （动防站 、防疫员）
          // const CHANGETYPE_RETURN_FROM: number = 2;  // 接收下级退还-入库 （中心、动防站）

          // const CHANGETYPE_DISTRIBUTE_TO: number = -1;   // 发放给下级-出库 （中心、动防站）
          // const CHANGETYPE_RETURN_TO: number = -2;   // 退还给上级-出库 （动防站 、防疫员）

          let params = {
            storeId,
            goodsId,
            page: 1,
            size: 1000
          }
          let res = await getInventoryChangeLog(params);
          try {
            if (res.code == 1000) {
              this.vaccinumInboundList = res.data.list.filter(item => item.changeType == 0 || item.changeType == 1)
            }
          }
          catch (err) {
            console.log(err)
          }
        },

        // 获取疫苗分发信息
        async getInventoryOutBountList(storeId, goodsId) {
          this.vaccinumOutboundList = []
          // const CHANGETYPE_INITIAL: number = 0; // 库存登记-入库 (中心 入库)
          // const CHANGETYPE_RECIEVE_FROM: number = 1; // 领取上级给的-入库 （动防站 、防疫员）
          // const CHANGETYPE_RETURN_FROM: number = 2;  // 接收下级退还-入库 （中心、动防站）

          // const CHANGETYPE_DISTRIBUTE_TO: number = -1;   // 发放给下级-出库 （中心、动防站）
          // const CHANGETYPE_RETURN_TO: number = -2;   // 退还给上级-出库 （动防站 、防疫员）

          let params = {
            storeId,
            goodsId,
            changeType: -1,
            page: 1,
            size: 1000
          }
          let res = await getInventoryChangeLog(params);
          try {
            if (res.code == 1000) {
              this.vaccinumOutboundList = res.data.list
            }
          }
          catch (err) {
            console.log(err)
          }
        },

        // 获取 动防站点-负责人信息
        async getHeadInfoBySiteId(headId) {
          let res = await getHeadInfoBySiteId(headId)
          try {
            if (res.code == 1000) {
              this.personData = res.data
            }
          } catch(err) {
            console.log(err)
          }
        },
  
        // 跳转到防疫员疫苗库存页面
        goToErpPerson(item) {
          this.$router.push(`/erpPerson/${item.targetStoreId}/${item.targetStoreName}/${item.targetManagerId}`);
        },
  
        // 监听子组件 传递 的信息
        handleClickVaccinum(val) {
          // 获取疫苗详情
          this.getVaccinumDetail(val.name)
  
          // 获取 疫苗入库记录
          this.getInventoryInBountList(this.storeId, val.id)
  
          // 获取 给防疫员的 分配 记录
          this.getInventoryOutBountList(this.storeId, val.id)
        },
  
        // 获取 疫苗详情
        async getVaccinumDetail(name) {
          let res = await getVaccinumTree(name);
          try {
            if (res.code == 1000 && res.data[0].children.length && res.data[0].children[0].name) {
              this.currentVaccinumInfo = res.data[0].children[0]
            } else {
              this.currentVaccinumInfo = {
                picture: '',
                name: '',
                description: ''
              }
            }
          }
          catch (err) {
            console.log(err)
          }
        },
  
        // 格式化 图表所用的 数据
        formatYiMiaoList(list) {
          let newList = []
          let item = {}
  
          for (let i in list) {
            item = { 
              id: list[i].goodsId,
              name: list[i].goodsName,
              value: list[i].quantity,
              unit: '毫升、头份',
            }
            newList.push(item)
          }
  
          return newList
        },
  
        // 监听 选择 防疫工作
        selectJob(jobId) {
          this.jobId = jobId

          // 获取 疫苗库存信息
          this.getInventoryList(this.storeId, jobId)
        },
      }
    }
  </script>